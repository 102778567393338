import { FrontendReactComponentsUiLinkCardProps } from '@workspace/frontend/react/components/ui-link-card';
import { PortfolioLink } from '../models/portfolio-link';

export function portfolioLinkAdapter(
  node: PortfolioLink
): FrontendReactComponentsUiLinkCardProps {
  return {
    imageUrl: node.image.url,
    overlayText: node.overlay_text.text,
    link: node.link.url,
  };
}

export function graphQueryProcessor(data: {
  allPrismicPortfoliolink: {
    edges: {
      node: {
        id: string;
        first_publication_date: string;
        data: PortfolioLink;
      };
    }[];
  };
}): FrontendReactComponentsUiLinkCardProps[] {
  return data.allPrismicPortfoliolink.edges.sort((a: any,b: any) => new Date(a.node.first_publication_date) > new Date(b.node.first_publication_date) ? -1 : 1).map((edge) =>
    portfolioLinkAdapter(edge.node.data)
  );
}
